.header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: 1px solid #d3d6da;
}

header .title {
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  text-align: center;
}

.logo {
  cursor: pointer;
}

.text-formula {
  font-size: 300%;
}

.text-problem-count {
  font-size: 150%;
}

.score {
  font-weight: bold;
  font-size: 20px;
}

.empty-box {
  height: 75px;
  border: 2px solid #d3d6da;
}

.notification {
  height: 66px;
}

.calcalator-button {
  background-color: #d3d6da;
  color: #000000;
  font-weight: bold;
  font-size: 20px;
}

// setting
.setting-popup {
  width: 300px;

  .setting-header {
    font-size: 130%;
  }

  .setting-reset {
    cursor: pointer;
  }

  .setting-range-value {
    margin-top: 7px;
  }

  .setting-version {
    font-size: 90%;
  }
}

.readme {
  font-size: 28px;
  font-weight: bold;
}

.btn {
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  .calcalator-button {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (min-width: 600px) {
  .calcalator-button {
    width: 80px;
    height: 80px;
  }
}
